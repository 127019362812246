import { metaTagContent } from "@/helpers/documentHelpers.js";

const enabledFeatures = () => {
  const features = metaTagContent('enabledFeatures');
  if (!features) return [];
  return features.split(',');
};

export const isFeatureEnabled = feature => {
  return enabledFeatures().includes(feature);
};
